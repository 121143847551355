import React from 'react';
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <div id='siteFooter'>
            <div className='footer-content' style={{ visibility: "hidden" }}>

                <div className='footer-col'>
                    <h3>Developer</h3>
                    <ul className='footer-list'>
                        <li>Python & Javascript</li>
                        <li><NavLink to='/portfolio'>My Work</NavLink></li>
                    </ul>
                </div>

                <div className='footer-col'>
                    <h3>Contact</h3>
                    <ul className='footer-list'>
                        <li><NavLink to='#'>Github</NavLink></li>
                        <li><NavLink to='/contact'>Contact</NavLink></li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Footer;
