import '../styles/homepage.css'
import React from 'react';
import ProjectArea from "./portfolio/ProjectArea";
import {Link} from "react-router-dom";

const Homepage = ({ projects }) => {

    return (
        <div className='content'>
            <h1>Hi, I'm Henry</h1>

            <div className='site-intro'>
                <h2>What I do</h2>
                <div className='intro-text-area'>
                    After graduating with a degree in Biochemistry, I moved into the tech industry as a QA Analyst.
                    I enjoy writing software, both as an exercise and for fun, and am always looking for ways to
                    improve my skills. My language of choice is Python, but I have recently found myself enjoying
                    JavaScript and Java. If you want to read more about my skills,
                    see <Link to='/about'>about me</Link>.
                    <br />
                    If you want to get in touch, please <a href={'mailto:henry@henryshakeshaft.com'}>send me an email</a>.
                </div>
            </div>

            <h2>Some of my work</h2>
            <div className='home-portfolio-summary'>
                This is a small excerpt of projects I have worked on - if you want to see more you can check out
                my <Link to='/portfolio'>portfolio</Link> or
                my <a href='https://github.com/H-Shakeshaft'>Github</a> account.
            </div>
            <ProjectArea projects={projects} />

        </div>
    )
}

export default Homepage;
