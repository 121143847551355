import './styles/App.css';
import './styles/navbar.css'
import './styles/footer.css'
import Navbar from "./components/partials/Navbar";
import Footer from "./components/partials/Footer";
import Homepage from "./components/Homepage";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import Contact from './components/Contact';
// import ProperContact from "./components/ProperContact";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useEffect, useState} from "react";

function App() {

    const [projects, setProjects] = useState([]);
    const [resume, setResume] = useState(undefined);

    const getPortfolio = () => {
        fetch('api/projects/')
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setProjects(data);
            })
    }

    useEffect ( () => {
        getPortfolio();
    }, [])


  return (
    <div className="App">

        <div className='main'>
            <Router>
                <Navbar />
                    <Switch>
                        <Route exact path='/'>
                            <Homepage projects={projects.slice(0,5)} />
                        </Route>

                        <Route exact path='/about'>
                            <About resume={resume} setResume={setResume} />
                        </Route>

                        <Route path='/portfolio'>
                            <Portfolio projects={projects} />
                        </Route>
                    </Switch>
                <Footer />
            </Router>
        </div>
    </div>
  );
}

export default App;
