import '../styles/about.css'
import React, {useEffect} from 'react';

const About = ({ resume, setResume }) => {

    const getResume = () => {
        fetch('api/resume/')
            .then(res => res.json())
            .then(data => {
                console.log(data[0].cv)
                setResume(data[0].cv);
            });
    }

    useEffect(() => {
        if (resume === undefined) {
            getResume();
        }
    }, [])

    return (
        <div className='content'>
            <div className='about-page'>
                <h1>About Me</h1>

                <div className='about-text'>
                    I graduated University in 2019, attaining a <em>Bachelor of Science 1st Class Honours in Biochemistry</em>.
                    During my final year I took on a research project which involved developing Python scripts to load,
                    analyse and report results from data created by the laboratory team. During this project I had to learn
                    how to code in Python, and developed an interest in software development, deciding to pursue
                    a career in it after university.
                </div>

                <div className='about-text'>
                    Shortly after leaving University, I began working at a Software Company as a <em>QA Analyst</em> - our
                    customers include Pharmaceutical and Biotechnology companies. During my time there I continued my
                    work with Python, developing data processing scripts and an internal library for
                    <em>automated regression testing</em> of our desktop products.
                </div>

                <div className='about-text'>
                    My most notable skills are:
                    <ul>
                        <li>Python</li>
                        <li>Django</li>
                        <li>JavaScript</li>
                    </ul>
                </div>

                <div className='about-text'>
                    If you want to see more about my skills and work history, you can download <a href={resume}>my CV</a>.
                </div>

            </div>

        </div>
    )
}

export default About;
