import React from 'react';
import { FaGithubSquare, FaExternalLinkSquareAlt } from 'react-icons/fa';

const Project = ({ index, title, description, evidence, links }) => {
    return (
        <div className='project' key={index}>

            <div className='p-col-1'>
                <div className='project-title'>{title}</div>

                <div className='project-body'>{description}</div>

                <div className='links'>
                    {
                        links &&
                        links.map((l, idx) => <a
                            href={l.link}
                            key={idx}>{l.is_code_link ? <FaGithubSquare /> : <FaExternalLinkSquareAlt /> }
                        </a>)
                    }
                    {
                        (links.length < 2) &&<a><svg className='hidden-pad' /></a>
                    }
                </div>
            </div>

            <div className='p-col-2'>
                {
                    evidence && (
                        evidence.is_video ? <video controls>
                            <source src={evidence.evidence} type='video/mp3' />
                            <source src={evidence.evidence} type='video/mp4' />
                        </video> : <img src={evidence.evidence} alt='project' className='project-media' />)
                }
            </div>

        </div>
    )
}

export default Project;
