import React from 'react';
import {NavLink} from "react-router-dom";

const Navbar = () => {
    return (
        <div className='navbar'>
            <div className='brand'>
                <NavLink exact to='/' activeClassName='activeRoute' >Henry Shakeshaft</NavLink>
            </div>

            <div className='nav-link'>
                <NavLink to='/about' activeClassName='activeRoute'>about</NavLink>
            </div>

            <div className='nav-link'>
                <NavLink to='/portfolio' activeClassName='activeRoute'>portfolio</NavLink>
            </div>

            <div className='nav-link'>
                {/*<NavLink to='/contact' activeClassName='activeRoute'>contact</NavLink>*/}
                <a href='mailto:henry@henryshakeshaft.com'>contact</a>
            </div>
        </div>
    )
}

export default Navbar;
