import React from 'react';
import Project from "./Project";

const ProjectArea = ({ projects }) => {
    return (
            <div className='project-area'>
                {
                    projects
                        .map((p, index) => <Project
                            key={index}
                            title={p.title}
                            description={p.description}
                            evidence={p.mediaevidence[0]}
                            links={p.links}
                        />)
                }
            </div>
    )
}

export default ProjectArea;
