import '../styles/project.css'
import React from 'react';
import ProjectArea from "./portfolio/ProjectArea";

const Portfolio = ({ projects }) => {
    return (
        <div className='content'>
            <ProjectArea projects={projects} />
        </div>
    )
}

export default Portfolio;
